import * as React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const DivStyled = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto 0 auto;

  .wrapper {
    padding: 0 3rem 3rem 3rem;
  }
`

const NotFoundPage = () => {
  return (
    <DivStyled>
      <div className="wrapper">
        <h1>Извините, страница не найдена 🤷</h1>
        <Link to="/">Вернуться на главную</Link>
      </div>
    </DivStyled>
  )
}

export default NotFoundPage
